import { ComponentProps, ReactElement, FunctionComponent } from 'react';
import { Root, Trigger, Portal, Content, Arrow, Close } from '@radix-ui/react-popover';
import BlueprintIcon from '../BlueprintIcon';
import styles from './styles.module.scss';
import classNames from 'classnames';

export type PopoverProps = {
    content: ReactElement;
    className?: string;
    children: ComponentProps<typeof Trigger>['children'];
};

export const Popover: FunctionComponent<PopoverProps> = ({ content, className, children }) => (
    <Root>
        <Trigger asChild>{children}</Trigger>
        <Portal>
            <Content className={classNames(styles.PopoverContent, className)} sideOffset={5}>
                {content}
                <Close className={styles.PopoverClose} aria-label="Close">
                    <BlueprintIcon icon="Close" />
                </Close>
                <Arrow className={styles.PopoverArrow} />
            </Content>
        </Portal>
    </Root>
);

export default Popover;
