'use client';

import Button from '../Button';
import styles from './styles.module.scss';
import listOfFeatures, { FeatureItem } from './listOfFeatures';
import Popover from '../Popover';
import { FunctionComponent } from 'react';

type FeatureProps = {
    feature: FeatureItem;
};

const Feature: FunctionComponent<FeatureProps> = ({ feature }) => (
    <Popover
        content={
            <div>
                <p className={styles.description}>{feature.description}</p>
            </div>
        }
    >
        <Button preset="default" className={styles.feature_button}>
            <span className={styles.btn_contents}>
                <span className={styles.icon}>{feature.icon}</span>
                {feature.label}
                {feature.badge ? <div className={styles.badge}>{feature.badge}</div> : null}
            </span>
        </Button>
    </Popover>
);

export const Features: FunctionComponent = () => {
    return (
        <div className={styles.features}>
            {listOfFeatures.map((feature) => (
                <Feature key={feature.label} feature={feature} />
            ))}
        </div>
    );
};
