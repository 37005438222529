import { Logos } from '.';

export const logos: Logos = {
    press: [
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/press/1.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/press/1.png`,
            alt: 'Business Insider Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/press/2.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/press/2.png`,
            alt: 'Business Live Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/press/3.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/press/3.png`,
            alt: 'Manchester Evening News Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/press/4.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/press/4.png`,
            alt: 'Daily Record Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/press/5.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/press/5.png`,
            alt: 'The National Logo',
        },
    ],
    educators: [
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/active-lancashire.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/active-lancashire.png`,
            alt: 'Active Lancashire Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/age-uk.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/age-uk.png`,
            alt: 'Age UK Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/manchester-university-nhs-trust.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/manchester-university-nhs-trust.png`,
            alt: 'Manchester University NHS Trust Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/mind.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/mind.png`,
            alt: 'Mind Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/central-film-school.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/central-film-school.png`,
            alt: 'Central Film School Logo',
        },
    ],
    clients: [
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/clients/acrisure.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/clients/acrisure.png`,
            alt: 'Acrisure Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/clients/noble-foods.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/clients/noble-foods.png`,
            alt: 'Noble Foods Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/clients/nhs.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/clients/nhs.png`,
            alt: 'NHS Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/active-lancashire.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/educators/active-lancashire.png`,
            alt: 'Active Lancashire Logo',
        },
        {
            imgSrc: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/clients/happy-egg-co.webp`,
            fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/LogosStrip/clients/happy-egg-co.png`,
            alt: 'Happy Eggs Logo',
        },
    ],
};
