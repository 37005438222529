import { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { logos } from './logos';
import { ImageWithFallback } from '../ImageWithFallback';

export type LogoStripCategory = 'press' | 'educators' | 'clients';

export type LogosStripProps = {
    heading: string;
    category: LogoStripCategory;
};

export type Logo = {
    imgSrc: string;
    fallback: string;
    alt: string;
};

export type Logos = {
    [key in LogoStripCategory]: Logo[];
};

export const LogosStrip: FunctionComponent<LogosStripProps> = ({ heading, category }) => {
    return (
        <section className={styles.logos_banner}>
            <h4>{heading}</h4>
            <div className={styles.logos}>
                {logos[category].map((logo) => {
                    return (
                        <ImageWithFallback
                            key={logo.imgSrc}
                            src={logo.imgSrc}
                            fallback={logo.fallback}
                            alt={logo.alt}
                            width={160}
                            height={39}
                        />
                    );
                })}
            </div>
        </section>
    );
};
